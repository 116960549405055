import { render, staticRenderFns } from "./IndexxPage2.vue?vue&type=template&id=24277f47&scoped=true"
import script from "./IndexxPage2.vue?vue&type=script&lang=js"
export * from "./IndexxPage2.vue?vue&type=script&lang=js"
import style0 from "./IndexxPage2.vue?vue&type=style&index=0&id=24277f47&prod&scoped=true&lang=css"
import style1 from "./IndexxPage2.vue?vue&type=style&index=1&id=24277f47&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24277f47",
  null
  
)

export default component.exports